import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CourseLab from "./CourseLab";
import { Link } from "react-router-dom";
import csefaculty from "../../../assets/ecedepartmentimg.png";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PageHeader } from "../../Header/PageHeader";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import "../Departments.css";
import Labs from "./Labs";
import Faculty from "./Faculty";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";

import axios from "axios";
import { resources } from "../../Resourses/Resources";

import {
  FluentShifts30Minutes24Filled,
  StreamlineBugAntivirusDebugging,
} from "../../../assets/icons/svgs";
import { UitCircuit } from "../../../assets/icons/svgs";
import { StreamlineBugAntivirusDebugging2 } from "../../../assets/icons/svgs";
import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
// import { StreamlineBugAntivirusDebuggingSolid } from "../../../assets/icons/svgs";
import { MdiPresentation } from "../../../assets/icons/svgs";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import QuizIcon from "@mui/icons-material/Quiz";
import Mr23 from "../../../Pdf/CourseStructure/UG/MR23_B.Tech_COURSE STRUCTURE.pdf";
import R20 from "../../../Pdf/CourseStructure/UG/R20 ECE Course Structure.pdf";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CourseStructure = () => {
  return (
    <div className="containe">
      <PageHeader name="ECE - COURSE STRUCTURE" />
      <div className="course_structure_cnt animate__animated animate__zoomIn">
        <div className="course text-center">
          <h4>Course Structure for all Years</h4>
          <p>
            Download the Course Structure and Syllabus of four years of ECE
            course
          </p>
        </div>

        <div className="w-100 text-center d-flex justify-content-center gap-5 ">
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              ECE - MR23
            </span>
            <a
              href="https://drive.google.com/file/d/1uGKNx1JH8St7VflSI7oV3xluZpYICMFD/view?usp=drive_link"
              target="_blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>ECE - R20</span>
            <a href={R20} target="_blank">
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          {/* <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              III ECE - R16
            </span>
            <a
              href="#"
              target="blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div>
          <div className="row-6">
            <span style={{ color: "red", marginRight: "10px" }}>
              IV ECE - R16
            </span>
            <a
              href="#"
              target="blank"
            >
              <PictureAsPdfIcon sx={{ fontSize: 50 }} />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};
const Events = () => {
  const getIcon1 = () => {
    return <FluentShifts30Minutes24Filled />;
  };

  const getIcon2 = () => {
    return <UitCircuit />;
  };

  const getIcon3 = () => {
    return <StreamlineBugAntivirusDebuggingSolid />;
  };

  const getIcon4 = () => {
    return <CoPresentIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const getIcon5 = () => {
    return <MdiPresentation />;
  };

  const getIcon6 = () => {
    return <QuizIcon sx={{ fontSize: 50, color: "orange" }} />;
  };

  const title1 = "Just-A-Minute";
  const desc1 =
    "is an all round-fun event that is all about the control of the mind over the mouth.";

  const title2 = "Instant Circuit Designing";
  const desc2 =
    "is a Competative event that all about the designing of Circuits Instantly.";

  const title3 = "Circuit Debugging";
  const desc3 = "Circuit Debugging is a Competative Event";

  const title4 = "Seminars";
  const desc4 =
    "Students give the demonistration on Various Latest Trends in Industry.";

  const title5 = "Poster Presentation";
  const desc5 =
    "is a way to share your knowledge of topic in a short format. It usually includes two elements - a poster and a brief explanation.";

  const title6 = "Quiz";
  const desc6 =
    "A quiz is a game or competition in which someone tests your knowledge by asking you questions";

  const IconCard = ({ icon, title, desc }) => {
    return (
      <>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 320,
            // height: 205,
            "&:hover": {
              boxShadow: "md",
              borderColor: "neutral.outlinedHoverBorder",
            },
          }}
        >
          <AspectRatio ratio="1" sx={{ width: 60 }}>
            {icon()}
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg" id="card-description">
              {title}
            </Typography>
            <p className="desc">{desc}</p>
          </CardContent>
        </Card>
      </>
    );
  };

  return (
    <div className="container">
      <PageHeader name="ACTIVITIES & EVENTS" />

      <div className="course text-center animate__animated animate__fadeInRight">
        <h4>Student Development Activities</h4>
        <br />
        {/* <p className="prg_content">
        
          In order to face the competitive world, a student must have good
          communication skills. In order to encourage the students to improve
          their inter personal and communication skills, to develop leadership
          qualities among students, Thrushna by Access have been formed.ECE
          association comprises the members from the faculty and the students
          from various years.
       
        <p >
          The Head of the Department will be the President for this association.
          This Association Students Forum conducts Student Meets, Guest Lectures
          and other Co-curricular and Extracurricular activities like Quizzes,
          Debates, Group Discussions, Just a minute, Elocution, Technical Talks,
          painting, tongue twister etc. These types of events greatly help the
          student community to come up with their ultimate potentialities.
        </p>
        </p> */}
      </div>
      {/* <div className="row mb-5">
        <div className="col-lg-4">
          <IconCard icon={getIcon1} title={title1} desc={desc1} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon2} title={title2} desc={desc2} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon3} title={title3} desc={desc3} />
        </div>
      </div> */}
      {/* <div className="row">
        <div className="col-lg-4">
          <IconCard icon={getIcon4} title={title4} desc={desc4} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon5} title={title5} desc={desc5} />
        </div>
        <div className="col-lg-4">
          <IconCard icon={getIcon6} title={title6} desc={desc6} />
        </div>
      </div> */}
    </div>
  );
};

const Obe = () => {
  return (
    <div className="container">
      <PageHeader name="PEOs/POs/PSOs" />
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM EDUCATIONAL OBJECTIVES(PEO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PEO 1:</h2>
              <p>
                
              To prepare graduates to become successful professionals in industry, government, academia, research and entrepreneurship.              </p>
            </div>
            <div class="peo">
              <h2>PEO 2:</h2>
              <p>
              To contribute for meeting the society needs in solving technical problems using electronics communications engineering ideology, tools and practices. 
              </p>
            </div>
            <div class="peo">
              <h2>PEO 3:</h2>
              <p>
              To inculcate professional and human values, effective communication skills, Team spirit & leadership qualities
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM OUTCOMES(PO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <div class="peo">
              <h2>PO 1 (Engineering knowledge):</h2>
              <p>
                Apply the knowledge of mathematics, science, engineering
                fundamentals, and an engineering specialization to the solution
                of complex engineering problems.
              </p>
            </div>
            <div class="peo">
              <h2>PO 2 (Problem analysis):</h2>
              <p>
                Identify, formulate, review research literature, and analyze
                complex engineering problems reaching substantiated conclusions
                using first principles of mathematics, natural sciences, and
                engineering sciences.
              </p>
            </div>
            <div class="peo">
              <h2>PO 3 (Design/development of solutions):</h2>
              <p>
                Design solutions for complex engineering problems and design
                system components or processes that meet the specified needs
                with appropriate consideration for the public health and safety,
                and the cultural, societal, and environmental considerations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 4 (Conduct investigations of complex problems):</h2>
              <p>
                Use research-based knowledge and research methods including
                design of experiments,analysis and interpretation of data, and
                synthesis of the information to provide valid conclusions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 5 (Modern tool usage):</h2>
              <p>
                Create, select, and apply appropriate techniques, resources, and
                modern engineering and IT tools including prediction and
                modelling to complex engineering activities with an
                understanding of the limitations.
              </p>
            </div>
            <div class="peo">
              <h2>PO 6 (The engineer and society):</h2>
              <p>
                Apply reasoning informed by the contextual knowledge to assess
                societal, health, safety, legal and cultural issues and the
                consequent responsibilities relevant to the professional
                engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 7 (Environment and sustainability):</h2>
              <p>
                Understand the impact of the professional engineering solutions
                in societal and environmental contexts, and demonstrate the
                knowledge of, and need for sustainable development.
              </p>
            </div>
            <div class="peo">
              <h2>PO 8 (Ethics):</h2>
              <p>
                Apply ethical principles and commit to professional ethics and
                responsibilities and norms of the engineering practice.
              </p>
            </div>
            <div class="peo">
              <h2>PO 9 (Individual and team work):</h2>
              <p>
                Function effectively as an individual, and as a member or leader
                in diverse teams, and in multidisciplinary settings.
              </p>
            </div>
            <div class="peo">
              <h2>PO 10 (Communication):</h2>
              <p>
                Communicate effectively on complex engineering activities with
                the engineering community and with society at large, such as,
                being able to comprehend and write effective reports and design
                documentation, make effective presentations, and give and
                receive clear instructions.
              </p>
            </div>
            <div class="peo">
              <h2>PO 11 (Project management and finance):</h2>
              <p>
                Demonstrate knowledge and understanding of the engineering and
                management principles and apply these to one’s own work, as a
                member and leader in a team, to manage projects and in
                multidisciplinary environments.
              </p>
            </div>
            <div class="peo">
              <h2>PO 12 (Life-long learning):</h2>
              <p>
                Recognize the need for, and have the preparation and ability to
                engage in independent and life-long learning in the broadest
                context of technological change.
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion> */}
      <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          PROGRAM SPECIFIC OUTCOMES (PSO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="pso-container">
            <div class="pso">
              <h2>PSO 1:</h2>
              <p>
              Apply domain specific knowledge for the design and analysis of recent trends in Electronics and Communication Engineering applications. 
              </p>
            </div>
            <div class="pso">
              <h2>PSO 2:</h2>
              <p>
              Capability in using electronic modern IT tools (both software and hardware) for solving complex problems in electronic and communication systems. 
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion sx={{ backgroundColor: "#fff", marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion_bg"
        >
          COURSE STRUCTURE (CO)
        </AccordionSummary>
        <AccordionDetails
          align="center"
          sx={{
            backgroundColor: "#fff5dc",
            color: "#000",
            fontSize: "12px",
            fontFamily: "Arial",
            marginBottom: "10px",
            letterSpacing: "1px",
          }}
        >
          <div class="peo-container">
            <ul class="course_outcomes_cnt">
              <li>
                <a
                  href="https://drive.google.com/file/d/1n3Qu55EsPjbyJDVHad3_JLPDCCPq67tR/view"
                  target="_blank"
                >
                  R18
                </a>
              </li>
              <li>
                <a
                  href="https://drive.google.com/file/d/1P2RLsSGtXP-DegiXSAc44UC9cERVTJPh/view"
                  target="_blank"
                >
                  R22
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};

const AboutDepartment = () => {
  return (
    <div>
      <div className="container">
        <PageHeader name="ELECTRONICS & COMMUNICATION ENGINEERING" />
        <div className="container-xxl">
          <div className="container-xxl py-1">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                  style={{ minHeight: "100px", height: "auto" }}
                >
                  <div className="animate__animated animate__zoomIn">
                    <img
                      className="img-fluid rounded w-80 h-100"
                      src={csefaculty}
                      alt="csefaculty-img"
                      style={{
                        objectFit: "contain",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6  fadeInUp" data-wow-delay="0.3s">
                  <div className="row gy-1 gx-1 mb-1">
                    <p className="prg_content_About">
                      <h1 className="mb-1">About</h1>
                      To cater to the growing demand of Electronic professionals
                      from global organizations, Sri Mittapalli College of
                      Engineering (SMCE) has set up an undergraduate course in
                      Electronics and Communication Engineering (B.Tech. ECE)
                      branch in 2006 with an initial intake of 60 and to 90 in
                      the following years. A post graduate course also has been
                      introduced in the year 2010 in Electronics and
                      Communication Engineering (M.Tech. VLSI&ES). Center of
                      Excellence Labs have been functioning on cutting edge
                      technologies such as Circuits Lab, Communications Lab,
                      Microwave and Optical Communication Lab, and Simulation
                      Lab, etc.
                    </p>

                    {/* <h4>Programmes Offered</h4>

                    <p>
                      1. UG – B.Tech.Electronics & Communication Engineering-
                      In-Take:60. 2.PG – M.Tech. VLSI & Embedded Systems-
                      In-Take:18.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            <div className="card_cntr1">
              {/* <div className="boxes1 service-item text-center pt-3 shadow"> */}
              <div className="hov">
                <h5 className="mb-1">Vision of the Department:</h5>
                <br />
                <p className="prg_content_cse">
                To produce innovative Electronics & Communication engineers by imparting quality technical education with ethical and moral values to meet the Global Standards..
                </p>
              </div>

              <div className="hov">
                <h5>Mission of the Department:</h5>
                <br />
                <div className="mission-container">
                  <h5 className="mission-sub-heading">M1</h5>
                  <p className="mission-sub-subscription">
                    {" "}
                    To establish an excellent teaching learning process that enables the students to face the challenges of the Electronics and Communication Engineering
                  </p>
                </div>
                <div className="mission-container">
                  <h5 className="mission-sub-heading">M2</h5>
                  <p className="mission-sub-subscription">
                    {" "}
                    To promote the inventions of allied technologies in Electronics and Communication Engineering to have exposure of global scenario.
                  </p>
                </div>
                <div className="mission-container">
                  <h5 className="mission-sub-heading">M3</h5>
                  <p className="mission-sub-subscription">
                    {" "}
                    To develop value based ethical leadership among the students for the prospective of society 
                  </p>
                </div>
                {/* <div>
                  <Accordion className="prg_content_cse"
                    sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ backgroundColor: "#fff" }}
                    >
                      M1
                    </AccordionSummary>
                    <AccordionDetails
                      align="center"
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      To educate students to the effective problem – solvers
                      and life – long learners in technical, innovative and
                      entrepreneurial skills.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{ backgroundColor: "#fff", marginBottom: "10px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      M2
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      To impart quality and value based education and
                      contribute towards the advancement of computing, science
                      and technology to raise satisfaction level of all
                      stakeholders.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      M3
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: "#fff5dc",
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Arial",
                        marginBottom: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      To establish a continuous Industry Institute
                      Interaction, participation, collaboration to contribute
                      skilled IT engineers.
                    </AccordionDetails>
                  </Accordion>
                </div> */}
              </div>

              <div className="hov">
                <h5 className="mb-3">Why Choose SMCE ECE:</h5>
                <br />
                <p className="prg_content_cse">
                  <ArrowForwardOutlinedIcon />
                  Qualified & Experienced Faculty.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  Industry ready course content.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  Well planned academic curriculum.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  Course delivery through real world applications.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  Career Prospects.
                </p>
              </div>
            </div>
          }

          <div className="container">
            <div className="row">
              <div className="area col-6">
                <h4>Focus Areas:</h4>
                <p className="prg_content">
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Embedded Systems.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Neural Networks.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Radar Systems.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Antennas.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Natural Language Processing.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Robotic Applications.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Signals & Systems.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Security Certifications.
                  <br />
                  <ArrowForwardOutlinedIcon />
                  &nbsp;&nbsp;Communication Networks
                </p>
              </div>
              <div className="area col-6">
                <h5>Skill Development:</h5>
                <br />
                <p>
                  <b> Coding & Designing:</b>Programming skills in C, C++, VLSI,
                  Chip Design, Ardunio, Raspberry Pi Boards.
                  <br />
                  <br />
                  <b>Soft skills:</b> Hobby clubs, Mock Interviews.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ECE = () => {
  const [value, setValue] = React.useState(0);
  const [departmentData, setDepartmentData] = useState([]);

  const handleGeteceDepartment = async () => {
    try {
      const res = await axios.get(
        resources.APPLICATION_URL + "getstudentdata" + "ECE"
      );
      if (res.data) {
        setDepartmentData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    handleGeteceDepartment();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "35px" }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="About Department" {...a11yProps(0)} />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="PEOs/POs/PSOs"
              {...a11yProps(1)}
            />
            <Tab label="Course Structure" {...a11yProps(2)} />
            <Tab label="Laboratories" {...a11yProps(3)} />
            {/* <Tab label="Faculty" {...a11yProps(4)} /> */}
            <Tab label="Activities & Events" {...a11yProps(4)} />
            <Tab label="Course Files & Lab-Manula" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <AboutDepartment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Obe />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CourseStructure />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Labs />
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={4}>
          <Faculty />
        </CustomTabPanel> */}
        <CustomTabPanel value={value} index={4}>
          <Events />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
        <CourseLab/>
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default ECE;
