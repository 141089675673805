import React from "react";
import "./Courselab.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import DIGITAL_IMAGE_PROCESSING from "../../../Pdf/Course_Labs/Course/DIGITAL.pdf";
import EMICOURSEconverted_ from "../../../Pdf/Course_Labs/Course/EMICOURSEconverted.pdf";
import OC_NOTES from "../../../Pdf/Course_Labs/Course/OC_NOTES.pdf";
import PTSP_2 from "../../../Pdf/Course_Labs/Course/PTSP_2.pdf";
import Radar_Systems_2 from "../../../Pdf/Course_Labs/Course/Radar_Systems_2.pdf";
import Satellite_Com from "../../../Pdf/Course_Labs/Course/Satellite-communications_3.pdf";
import VLSI_course from "../../../Pdf/Course_Labs/Course/VLSI_course file1.pdf";

import DSP_Lab from "../../../Pdf/Course_Labs/Labs/DSP_LAB_manual.pdf";
import SS_lab from "../../../Pdf/Course_Labs/Labs/SS_lab_Manual_R23.pdf";
import STLD_lab from "../../../Pdf/Course_Labs/Labs/STLD_lab_manual.pdf";
import VLSI_LAB from "../../../Pdf/Course_Labs/Labs/VLSI_LAB.pdf";

const CourseLab = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const members = [
    { acadmicyear: "Course File 1", pdfpath: DIGITAL_IMAGE_PROCESSING },
    { acadmicyear: "Course File 2", pdfpath: EMICOURSEconverted_ },
    { acadmicyear: "Course File 3", pdfpath: OC_NOTES },
    { acadmicyear: "Course File 4", pdfpath: PTSP_2 },
    { acadmicyear: "Course-File 5", pdfpath: Radar_Systems_2 },
    { acadmicyear: "Course-File 6", pdfpath: Satellite_Com },
    { acadmicyear: "Course-File 7", pdfpath: VLSI_course },
  ];

  const aqar = [
    { year: "DSP-lab", link: DSP_Lab },
    { year: "SS-lab", link: SS_lab },
    { year: "STLD-lab", link: STLD_lab },
    { year: "VLSI-LAB", link: VLSI_LAB },
  ];

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1", textAlign: "center" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              centered
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="Course Files"
                value="1"
              />
              <Tab
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "rgb(37, 31, 31)",
                }}
                label="Lab-Manuals"
                value="2"
              />
            </TabList>
          </Box>

          <TabPanel value="1">
            <h2 style={{ fontFamily: "Lucid", fontWeight: "bold" }}>
              {" "}
              Course Files
            </h2>
            <div className="aqar_button_container">
              {members.map((each, index) => (
                <a
                  href={each.pdfpath}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <button>{each.acadmicyear}</button>
                </a>
              ))}
            </div>
          </TabPanel>

          <TabPanel value="2">
            <h2 style={{ fontFamily: "Lucid", fontWeight: "bold" }}>
              Lab-Manuals
            </h2>
            <div className="aqar_button_container">
              {aqar.map((each, index) => (
                <a
                  href={each.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <button>{each.year}</button>
                </a>
              ))}
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default CourseLab;
