import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { PageHeader } from "../../Header/PageHeader";
import "../Departments.css";
import "animate.css";

// communication lab

import comm1 from "../../../assets/lab1.jpeg";
import comm2 from "../../../assets/lab3.jpeg";
import comm3 from "../../../assets/lab4.jpeg";

// Simulation Lab

import sim1 from "../../../assets/EDC_LAB/DSC00158.JPG";
import sim2 from "../../../assets/lab3.jpeg";
import sim3 from "../../../assets/lab2.jpeg";

// Edc Lab

import edc1 from "../../../assets/EDC_LAB/1P8A3089.JPG";
import edc2 from "../../../assets/EDC_LAB/1P8A3133.JPG";
import edc3 from "../../../assets/EDC_LAB/1P8A3096.JPG";

// Icdsd Lab

import icd1 from "../../../assets/EDC_LAB/DSC00185.JPG";
import icd2 from "../../../assets/EDC_LAB/DSC00166.JPG";
import icd3 from "../../../assets/EDC_LAB/1P8A3089.JPG";

// Mpmc Lab

import mpmc1 from "../../../assets/EDC_LAB/DSC00187.JPG";
import mpmc2 from "../../../assets/EDC_LAB/DSC00259.JPG";
import mpmc3 from "../../../assets/EDC_LAB/DSC00256.JPG";

const Labs = () => {
  const [key, setKey] = useState("All");

  return (
    <div className="container">
      <PageHeader name="Laboratories" />
      <Card style={{ width: "100%", margin: "20px 0px" }}>
        <Card.Header>ECE Laboratories</Card.Header>
        <Card.Body>
          <Card.Title>We have Fully Equipped Laboratories</Card.Title>
          <Card.Text>
            we have the several Computer Labs equipped with latest software's
            and workstations. It is connected to internet through a lease line
            to facilitate high speed internet access. All the computers are
            connected through a structured network. Printer and copier
            facilities are also available in the lab. Our dedicated faculty and
            staff are proud of their impressive credentials, which are
            comparable to those of the best career instructors.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="content">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="All" title="All">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
            <br />

            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd3}
                    alt="lab-img"
                    style={{height:"300px"}}
                  />
                  <br />
                </div>
              </div>
            </div>

            <br />
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="COMMUNICATION LAB" title="COMMUNICATION LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={comm3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="SIMULATION LAB" title="SIMULATION LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={sim3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="MP&MC LAB" title="MP&MC LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-10"
                    src={mpmc3}
                    alt="lab-img"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="IC/DSD LAB " title="IC/DSD LAB ">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={icd3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="EDC LAB" title="EDC LAB">
            <div className="col-lg-12 align center col-md-12 animate__animated animate__zoomIn">
              <div className="row g-12 pt-12">
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc1}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc2}
                    alt="lab-img"
                  />
                </div>
                <div className="col-4 ">
                  <img
                    className="img-fluid bg-light p-10"
                    src={edc3}
                    alt="lab-img"
                  />
                  <br />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Labs;
